import React from 'react';
import { PiWarningCircle } from 'react-icons/pi';

import { divide } from '@bae/utils';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Label } from '@/components/ui/label.tsx';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';
import useMistStore from '@/features/digital-twin/MistSettings/store';

const LicenseAccordions = () => {
    const optionalLicenses = useMistStore(
        (state) => state.data.optionalLicenses,
    );
    const updateOptionalLicenses = useMistStore(
        (state) => state.updateOptionalLicense,
    );

    return (
        <Accordion type='single' collapsible className='w-full'>
            {optionalLicenses.map((vendorEntry) => (
                <React.Fragment key={vendorEntry.vendor}>
                    {/* Access Points Section */}
                    <AccordionItem value={`${vendorEntry.vendor}-aps`}>
                        <AccordionTrigger className='cursor-pointer rounded-lg border-none bg-newDesign-background p-2'>
                            <div className='flex w-full items-center justify-between pr-10'>
                                {/*<p>{`${vendorEntry.vendor} Access Points`}</p>*/}
                                <p>{`Access Points`}</p>
                                <div className='flex items-center gap-2'>
                                    <Label>License Term</Label>
                                    <Select
                                        value={divide(
                                            vendorEntry.aps.term,
                                            12,
                                        ).toString()}
                                        onValueChange={(val) =>
                                            updateOptionalLicenses(
                                                vendorEntry.vendor,
                                                'aps',
                                                (current) => ({
                                                    ...current,
                                                    term: parseInt(val) * 12,
                                                }),
                                            )
                                        }
                                        defaultValue='1'>
                                        <SelectTrigger className='border-tag h-8 w-[100px]'>
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {['1', '3', '5'].map((term) => (
                                                    <SelectItem
                                                        key={term}
                                                        value={term}>
                                                        {term + ' Year'}
                                                    </SelectItem>
                                                ))}
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </AccordionTrigger>

                        <AccordionContent>
                            <div className='flex flex-col gap-4 p-4'>
                                {vendorEntry.aps.licenses.map(
                                    (license, index) => (
                                        <div
                                            className='flex items-center space-x-2'
                                            key={license.skuBase}>
                                            <Checkbox
                                                onCheckedChange={(checked) => {
                                                    updateOptionalLicenses(
                                                        vendorEntry.vendor,
                                                        'aps',
                                                        (current) => {
                                                            const newLicenses =
                                                                [
                                                                    ...current.licenses,
                                                                ];
                                                            const delta =
                                                                checked
                                                                    ? 1
                                                                    : -1;
                                                            newLicenses[index] =
                                                                {
                                                                    ...license,
                                                                    status: !!checked,
                                                                };
                                                            return {
                                                                ...current,
                                                                licenses:
                                                                    newLicenses,
                                                                total_number:
                                                                    current.total_number +
                                                                    delta,
                                                            };
                                                        },
                                                    );
                                                }}
                                                checked={license.status}
                                                disabled={license.always_true}
                                                id={`${vendorEntry.vendor}-aps-${license.skuBase}`}
                                            />
                                            <Label
                                                htmlFor={`${vendorEntry.vendor}-aps-${license.skuBase}`}>
                                                {`${license.nameTag} (${license.skuBase})`}
                                            </Label>
                                        </div>
                                    ),
                                )}
                            </div>

                            {vendorEntry.aps.total_number >= 3 && (
                                <div className='flex flex-wrap items-center gap-2 rounded-xl border p-4 text-newDesign-info'>
                                    <span className='flex items-center gap-2 whitespace-nowrap'>
                                        <PiWarningCircle className='size-4 min-h-4 min-w-4' />
                                        <p className='whitespace-normal'>
                                            If more than two licenses are
                                            selected, then SUB-AI is the most
                                            cost-effective.
                                        </p>
                                    </span>
                                    <strong>
                                        It will be applied automatically.
                                    </strong>
                                </div>
                            )}
                        </AccordionContent>
                    </AccordionItem>

                    {/* Switches Section */}
                    <AccordionItem value={`${vendorEntry.vendor}-switches`}>
                        <AccordionTrigger className='cursor-pointer rounded-lg border-none bg-newDesign-background p-2'>
                            <div className='flex w-full items-center justify-between pr-10'>
                                {/*<p>{`${vendorEntry.vendor} Switches`}</p>*/}
                                <p>{`Switches`}</p>

                                <div className='flex items-center gap-2'>
                                    <div className='flex items-center gap-2'>
                                        <Label>Maintenance</Label>
                                        <Select
                                            value={
                                                vendorEntry.switches.maintenance
                                            }
                                            onValueChange={(val) => {
                                                console.log(val);

                                                updateOptionalLicenses(
                                                    vendorEntry.vendor,
                                                    'switches',
                                                    (current) => ({
                                                        ...current,
                                                        maintenance: val,
                                                    }),
                                                );
                                            }}
                                            defaultValue='1'>
                                            <SelectTrigger className='border-tag h-8 w-[150px]'>
                                                <SelectValue placeholder='No Maintenance' />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {[
                                                        'COR',
                                                        'S',
                                                        'N',
                                                        'none',
                                                    ].map((code) => (
                                                        <SelectItem
                                                            key={code}
                                                            value={code}>
                                                            {code === 'none'
                                                                ? 'No Maintenance'
                                                                : code}
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div className='flex items-center gap-2'>
                                        <Label>License Term</Label>
                                        <Select
                                            value={divide(
                                                vendorEntry.switches.term,
                                                12,
                                            ).toString()}
                                            onValueChange={(val) =>
                                                updateOptionalLicenses(
                                                    vendorEntry.vendor,
                                                    'switches',
                                                    (current) => ({
                                                        ...current,
                                                        term:
                                                            parseInt(val) * 12,
                                                    }),
                                                )
                                            }
                                            defaultValue='1'>
                                            <SelectTrigger className='border-tag h-8 w-[100px]'>
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {['1', '3', '5'].map(
                                                        (term) => (
                                                            <SelectItem
                                                                key={term}
                                                                value={term}>
                                                                {term + ' Year'}
                                                            </SelectItem>
                                                        ),
                                                    )}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </AccordionTrigger>

                        <AccordionContent>
                            <div className='flex flex-col gap-4 p-4'>
                                {vendorEntry.switches.licenses.map(
                                    (license, index) => (
                                        <div
                                            className='flex items-center space-x-2'
                                            key={license.skuBase}>
                                            <Checkbox
                                                onCheckedChange={(checked) => {
                                                    updateOptionalLicenses(
                                                        vendorEntry.vendor,
                                                        'switches',
                                                        (current) => {
                                                            const newLicenses =
                                                                [
                                                                    ...current.licenses,
                                                                ];
                                                            const delta =
                                                                checked
                                                                    ? 1
                                                                    : -1;
                                                            newLicenses[index] =
                                                                {
                                                                    ...license,
                                                                    status: !!checked,
                                                                };
                                                            return {
                                                                ...current,
                                                                licenses:
                                                                    newLicenses,
                                                                total_number:
                                                                    current.total_number +
                                                                    delta,
                                                            };
                                                        },
                                                    );
                                                }}
                                                checked={license.status}
                                                disabled={license.always_true}
                                                id={`${vendorEntry.vendor}-switches-${license.skuBase}`}
                                            />
                                            <Label
                                                htmlFor={`${vendorEntry.vendor}-switches-${license.skuBase}`}>
                                                {`${license.nameTag} (${license.skuBase})`}
                                            </Label>
                                        </div>
                                    ),
                                )}
                            </div>

                            {vendorEntry.switches.total_number >= 3 && (
                                <div className='flex flex-wrap items-center gap-2 rounded-xl border p-4 text-newDesign-info'>
                                    <span className='flex items-center gap-2 whitespace-nowrap'>
                                        <PiWarningCircle className='size-4 min-h-4 min-w-4' />
                                        <p className='whitespace-normal'>
                                            If more than two licenses are
                                            selected, then SUB-AI is the most
                                            cost-effective.
                                        </p>
                                    </span>
                                    <strong>
                                        It will be applied automatically.
                                    </strong>
                                </div>
                            )}
                        </AccordionContent>
                    </AccordionItem>
                </React.Fragment>
            ))}
        </Accordion>
    );
};

export default LicenseAccordions;
