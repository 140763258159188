import { z } from 'zod';

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { GrPowerReset } from 'react-icons/gr';
import { LuMail, LuUser } from 'react-icons/lu';
import { toast } from 'react-toastify';

import { useUser } from '@bae/auth';

import { Button } from '@/components/ui/button.tsx';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { useEditUserName } from '@/features/user-settings/api/edit-user-name.ts';

import { zodResolver } from '@hookform/resolvers/zod';

const spacesInSequenceRegex = /\s{2,}/g;

// Regex to check for allowed characters in the name
// This regex allows for a first name, any number of middle names, and a last name.
// Each name part must be at least 2 characters long.
// The pattern allows for spaces, commas, periods, apostrophes, and hyphens.
const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+(?:\s[A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+)*$/;

// Regex to check for inappropriate special characters
const invalidCharRegex = /[@#$%&.,*()=+[\]{}|<>?/!]/;

const userFormSchema = z.object({
    name: z
        .string()
        .min(4, { message: 'Name must be more than 4 characters long.' })
        .max(50, { message: 'Name must be no more than 50 characters long.' })
        .refine(
            (name) => !spacesInSequenceRegex.test(name),
            "Name can't have multiple spaces in a row.",
        )
        .refine(
            (name) => !invalidCharRegex.test(name) && nameRegex.test(name),
            'Name must not include numbers or special characters such as @,!, %, &.',
        ),

    email: z.string().email(),
});

const UserSettingsForm = () => {
    const { user, setUser, isFetchingUserData } = useUser();

    const form = useForm<z.infer<typeof userFormSchema>>({
        resolver: zodResolver(userFormSchema),
        defaultValues: {
            name: user?.fullname || '',
            email: user?.email || '',
        },
    });

    const editUserNameQuery = useEditUserName({
        mutationConfig: {
            onSuccess: () => {
                toast.success('User name updated successfully', {
                    autoClose: 2000,
                });
                setUser({ ...user, fullname: form.getValues('name') });
            },
            onError: () => {
                toast.error('Failed to update user name');
            },
        },
    });

    useEffect(() => {
        if (user?.fullname) form.setValue('name', user.fullname);
        if (user?.email) form.setValue('email', user.email);
    }, [user]);

    const onSubmit = (values: z.infer<typeof userFormSchema>) => {
        editUserNameQuery.mutate({ name: values.name });
    };

    const valuesHaveChanged =
        form.formState.isDirty &&
        (user.fullname !== form.watch('name') ||
            user.email !== form.watch('email'));

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
                <FormField
                    control={form.control}
                    name='email'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                E-mail
                            </FormLabel>
                            <FormControl>
                                <div className='relative'>
                                    <LuMail className='absolute left-2 top-1/2 size-4 -translate-y-1/2 transform text-newDesign-primary' />
                                    <Input
                                        className='pointer-events-none pl-8 text-newDesign-text-secondary'
                                        disabled={false}
                                        placeholder='E-mail...'
                                        {...field}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name='name'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                Full Name
                            </FormLabel>
                            <FormControl>
                                <div className='relative'>
                                    <LuUser className='absolute left-2 top-1/2 size-4 -translate-y-1/2 transform text-newDesign-primary' />
                                    <Input
                                        autoComplete={'off'}
                                        className='pl-8'
                                        disabled={
                                            isFetchingUserData ||
                                            editUserNameQuery.isPending
                                        }
                                        placeholder='Full name...'
                                        {...field}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />

                <Separator className='w-[calc(100%+3rem)] translate-x-[-1.5rem]' />
                <div className='flex justify-end'>
                    <Button
                        disabled={
                            editUserNameQuery.isPending || !valuesHaveChanged
                        }
                        variant='outline'
                        type='submit'>
                        {editUserNameQuery.isPending && (
                            <GrPowerReset className='mr-2 size-4 animate-spin text-newDesign-primary' />
                        )}
                        Save
                    </Button>
                </div>
            </form>
        </Form>
    );
};

export default UserSettingsForm;
