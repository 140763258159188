import React from 'react';
import { HiOutlineCurrencyDollar } from 'react-icons/hi';

import { Currency, useCurrency } from '@bae/store';
import { formatCurrencySymbol } from '@bae/utils';

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select.tsx';

import EuropeFlag from './flags/eu.svg';
import UKFlag from './flags/gb.svg';
import USAFlag from './flags/us.svg';

const getCountryFlag = (code: string) => {
    switch (code) {
        case 'EUR':
            return <EuropeFlag />;
        case 'GBP':
            return <UKFlag />;
        case 'USD':
            return <USAFlag />;
        default:
            return null;
    }
};

const CurrencySettings = () => {
    const { currency, CURRENCIES, handleSetCurrency, loading } = useCurrency();

    const getCurrencyOptions = () => {
        return Object.entries(CURRENCIES).map(([currency, name]) => ({
            value: currency,
            label: `${formatCurrencySymbol({
                currency,
                onlySymbol: true,
            })} - ${name}`,
            flag: getCountryFlag(currency) || null,
        }));
    };

    return (
        <section className='h-[160px] rounded-lg border border-newDesign-divider p-6'>
            <div className='flex items-center'>
                <HiOutlineCurrencyDollar className='mr-2 size-6 stroke-[1.5] text-newDesign-primary' />
                <h2 className='text-lg font-semibold'>Account Currency</h2>
            </div>
            <p className='mt-4 font-light'>Choose your preferred currency</p>

            <Select
                disabled={loading}
                onValueChange={(value) => handleSetCurrency(value as Currency)}
                value={currency}>
                <SelectTrigger className='mt-2'>
                    <SelectValue placeholder='Select currency' />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        {getCurrencyOptions().map((option) => (
                            <SelectItem key={option.value} value={option.value}>
                                <div className='flex items-center gap-2'>
                                    <span className='flex size-6 items-center justify-center overflow-hidden rounded-full'>
                                        {option.flag}
                                    </span>
                                    <span>{option.label}</span>
                                </div>
                            </SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </Select>
        </section>
    );
};

export default CurrencySettings;
