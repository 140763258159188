import React from 'react';

interface JuniperMistLogoProps {
    className?: string;
}

export const JuniperMistLogo: React.FC<JuniperMistLogoProps> = ({
    className = '',
}) => (
    <svg className={className} viewBox='0 0 190 189.7'>
        <g>
            <polygon
                points='190,0 102.2,0 102.2,40.9 149.1,40.9 149.1,87.8 190,87.8'
                style={{ fill: 'rgb(232, 114, 0)' }}
            />
            <polygon
                points='50.9,51.4 50.9,91.5 98.5,91.5 98.5,139.2 138.7,139.2 138.7,51.4'
                style={{ fill: 'rgb(132, 177, 53)' }}
            />
            <rect
                y='102'
                width='87.6'
                height='87.6'
                style={{ fill: 'rgb(0, 149, 169)' }}
            />
        </g>
    </svg>
);
