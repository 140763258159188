import { ShieldCheck } from 'lucide-react';
import React, { useState } from 'react';

import { VendorConfigForm } from './components/VendorConfigForm';
import { VendorList } from './components/VendorList';
import {
    initialVendorConfigs,
    testVendorConnection,
    disconnectVendorConfig,
} from './types.ts';
import type { Vendor, VendorConfigs } from './types.ts';

const VendorIntegrationMenu = () => {
    const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
    const [vendorConfigs, setVendorConfigs] =
        useState<VendorConfigs>(initialVendorConfigs);

    // handlers
    const handleSelectVendor = (vendor: Vendor) => {
        // Only set vendor if it's not connected
        !vendorConfigs[vendor].isConnected && setSelectedVendor(vendor);
    };

    const handleDisconnect = (vendor: Vendor) => {
        setVendorConfigs((prev) => ({
            ...prev,
            [vendor]: disconnectVendorConfig(),
        }));
        setSelectedVendor(null);
    };

    const handleConfigChange = (
        vendor: Vendor,
        field: 'organizationId' | 'apiToken',
        value: string,
    ) => {
        setVendorConfigs((prev) => ({
            ...prev,
            [vendor]: {
                ...prev[vendor],
                [field]: value,
            },
        }));
    };

    const handleTestConnection = async (vendor: Vendor) => {
        // set isLoading to true
        setVendorConfigs((prev) => ({
            ...prev,
            [vendor]: {
                ...prev[vendor],
                isLoading: true,
            },
        }));

        const updatedConfig = await testVendorConnection(
            vendor,
            vendorConfigs[vendor],
        );
        setVendorConfigs((prev) => ({
            ...prev,
            [vendor]: updatedConfig,
        }));
    };

    // computed
    const connectedCount = Object.values(vendorConfigs).filter(
        (c) => c.isConnected,
    ).length;

    return (
        <div className='mx-auto flex w-[600px] flex-col gap-4'>
            <section className='rounded-lg border border-newDesign-divider p-6'>
                <div className='mb-2 flex items-center gap-2'>
                    <ShieldCheck className='size-6 stroke-[1.5] text-newDesign-primary' />
                    <h2 className='text-lg font-semibold'>
                        Vendor Integration
                    </h2>
                </div>
                <p className='my-4 font-light'>
                    Connect your network infrastructure vendors to enable
                    seamless management and monitoring.
                </p>

                <h3 className='mb-6 flex items-center gap-2 text-base font-semibold'>
                    <span>Available Vendors</span>
                    <span className='text-sm font-normal text-gray-500'>
                        ({connectedCount} connected)
                    </span>
                </h3>

                {/* Vendor List */}
                <VendorList
                    vendorConfigs={vendorConfigs}
                    selectedVendor={selectedVendor}
                    onSelectVendor={handleSelectVendor}
                    onDisconnect={handleDisconnect}
                />

                {/* Vendor Config Form: only show if a vendor is selected and not connected */}
                {selectedVendor &&
                    !vendorConfigs[selectedVendor].isConnected && (
                        <VendorConfigForm
                            vendorKey={selectedVendor}
                            config={vendorConfigs[selectedVendor]}
                            onConfigChange={handleConfigChange}
                            onTestConnection={handleTestConnection}
                        />
                    )}
            </section>
        </div>
    );
};

export default VendorIntegrationMenu;
