import { z } from 'zod';

import React from 'react';
import { useForm } from 'react-hook-form';
import { GrPowerReset } from 'react-icons/gr';
import { toast } from 'react-toastify';

import { Button } from '@/components/ui/button.tsx';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { useEditUserPassword } from '@/features/user-settings/api/edit-user-password.ts';

import { zodResolver } from '@hookform/resolvers/zod';

interface PasswordError {
    message: string;
    error: boolean;
}

export const validatePassword = (
    password: string,
    confirmationPassword: string,
): PasswordError[] => {
    return [
        {
            message: 'Password must contain a lower case letter',
            error: !/[a-z]/.test(password),
        },
        {
            message: 'Password must contain an upper case letter',
            error: !/[A-Z]/.test(password),
        },
        {
            message: 'Password must contain a number',
            error: !/[0-9]/.test(password),
        },
        {
            message: 'Password must be at least 8 characters long',
            error: password.length < 8,
        },
        {
            message: 'Password must contain a special character or a space',
            error: !/[^A-Za-z0-9]/.test(password),
        },
        {
            message: 'Password must not contain leading or trailing spaces',
            error: /^\s|\s$/.test(password),
        },
        {
            message: 'Passwords do not match',
            error: password !== confirmationPassword,
        },
    ];
};

const passwordFormSchema = z
    .object({
        previousPassword: z.string().min(1, 'Current password is required.'),
        proposedPassword: z.string().min(1, 'New password is required.'),
        passwordConfirmation: z
            .string()
            .min(1, 'Password confirmation is required.'),
    })
    .superRefine(({ proposedPassword, passwordConfirmation }, ctx) => {
        const errors = validatePassword(proposedPassword, passwordConfirmation);
        errors.forEach(({ error, message }) => {
            if (error) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: message,
                    path: ['passwordConfirmation'],
                });
            }
        });
    });

const PasswordForm = ({ showPassword }: { showPassword: boolean }) => {
    const form = useForm<z.infer<typeof passwordFormSchema>>({
        resolver: zodResolver(passwordFormSchema),
        defaultValues: {
            previousPassword: '',
            proposedPassword: '',
            passwordConfirmation: '',
        },
    });

    const editUserPasswordQuery = useEditUserPassword({
        mutationConfig: {
            onSuccess: () => {
                toast.success('Password updated successfully', {
                    autoClose: 2000,
                });
            },
            onError: () => {
                toast.error('Failed to update password');
            },
        },
    });

    const onSubmit = (values: z.infer<typeof passwordFormSchema>) => {
        editUserPasswordQuery.mutate({
            previousPassword: values.previousPassword,
            proposedPassword: values.proposedPassword,
        });
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
                <FormField
                    disabled={editUserPasswordQuery.isPending}
                    control={form.control}
                    name='previousPassword'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                Current Password
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your current password'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <FormField
                    disabled={editUserPasswordQuery.isPending}
                    control={form.control}
                    name='proposedPassword'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                New Password
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter your new password'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <FormField
                    disabled={editUserPasswordQuery.isPending}
                    control={form.control}
                    name='passwordConfirmation'
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel className='text-newDesign-text-secondary'>
                                Confirm New Password
                            </FormLabel>
                            <FormControl>
                                <Input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Repeat the password'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage className='text-newDesign-error-dark' />
                        </FormItem>
                    )}
                />
                <Separator className='w-[calc(100%+3rem)] translate-x-[-1.5rem]' />
                <div className='flex justify-end gap-4'>
                    <Button
                        disabled={editUserPasswordQuery.isPending}
                        variant='outline'
                        type='submit'>
                        {editUserPasswordQuery.isPending && (
                            <GrPowerReset className='mr-2 size-4 animate-spin text-newDesign-primary' />
                        )}
                        Change Password
                    </Button>
                </div>
            </form>
        </Form>
    );
};

export default PasswordForm;
