import React from 'react';

import type { Vendor, VendorConfigs } from '../types.ts';
import { VendorCard } from './VendorCard';

interface VendorListProps {
    vendorConfigs: VendorConfigs;
    selectedVendor: Vendor | null;
    onSelectVendor: (vendor: Vendor) => void;
    onDisconnect: (vendor: Vendor) => void;
}

export function VendorList({
    vendorConfigs,
    selectedVendor,
    onSelectVendor,
    onDisconnect,
}: VendorListProps) {
    return (
        <div className='mb-8 grid grid-cols-1 gap-2 md:grid-cols-2'>
            {Object.entries(vendorConfigs).map(([vendor, config]) => {
                const v = vendor as Vendor;

                return (
                    <VendorCard
                        key={v}
                        vendorKey={v}
                        config={config}
                        selectedVendor={selectedVendor}
                        onSelect={onSelectVendor}
                        onDisconnect={onDisconnect}
                    />
                );
            })}
        </div>
    );
}
