import { AppTypes } from '@/app-config/appTypes.ts';
import { getAppType } from '@/app-config/featureConfig.ts';
import { Separator } from '@/components/ui/separator.tsx';
import VendorIntegrationMenu from '@/features/digital-twin/VendorIntegrationMenu/VendorIntegrationMenu.tsx';
import CurrencySettings from '@/features/organizations/components/CurrencySettings/CurrencySettings.tsx';

const OrganizationSettings = () => {
    return (
        <div className='flex w-full flex-1 flex-col gap-4'>
            <h1 className='text-2xl font-bold'>Organization Settings</h1>
            <Separator />
            <div className='mx-auto flex w-[600px] flex-col gap-4 pb-4'>
                <CurrencySettings />

                {getAppType() === AppTypes.MIST && <VendorIntegrationMenu />}
            </div>
        </div>
    );
};

export default OrganizationSettings;
