import { Separator } from '@/components/ui/separator.tsx';
import UserSettings from '@/features/user-settings/components/UserSettings/UserSettings.tsx';

const AccountSettings = () => {
    return (
        <div className='flex w-full flex-1 flex-col gap-4'>
            <h1 className='text-2xl font-bold text-newDesign-black'>
                Account Settings
            </h1>
            <Separator />
            <div className='mx-auto flex w-[600px] flex-col gap-4'>
                <UserSettings />
            </div>
        </div>
    );
};

export default AccountSettings;
