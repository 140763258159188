import { AlertCircle, ShieldCheck, Loader2 } from 'lucide-react';
import React from 'react';

import { Button } from '@/components/ui/button.tsx';

import type { Vendor, VendorConfig } from '../types.ts';

interface VendorConfigFormProps {
    vendorKey: Vendor;
    config: VendorConfig;
    onConfigChange: (
        vendor: Vendor,
        field: 'organizationId' | 'apiToken',
        value: string,
    ) => void;
    onTestConnection: (vendor: Vendor) => void;
}

export function VendorConfigForm({
    vendorKey,
    config,
    onConfigChange,
    onTestConnection,
}: VendorConfigFormProps) {
    const handleChange =
        (field: 'organizationId' | 'apiToken') =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onConfigChange(vendorKey, field, e.target.value);
        };

    const canTest =
        !config.isLoading && config.organizationId && config.apiToken;

    return (
        <div className='animate-fadeIn border-t pt-6'>
            <div className='mb-6 flex items-center gap-2'>
                <h3 className='text-base font-medium'>Configure Vendor</h3>
            </div>

            <div className='mb-6 rounded-lg border border-blue-100 bg-blue-50 p-4'>
                <div className='flex gap-2'>
                    <AlertCircle className='mt-0.5 h-5 w-5 flex-shrink-0 text-newDesign-primary-dark' />
                    <div>
                        <h5 className='mb-1 text-sm font-medium text-newDesign-primary-dark'>
                            Before you begin
                        </h5>
                        <p className='text-sm text-newDesign-primary-dark'>
                            {`Make sure you have your organization ID and API
                                token ready. You can find these in your vendor's
                                dashboard. Check the guide`}{' '}
                            <strong className='cursor-pointer hover:underline'>
                                here
                            </strong>
                        </p>
                    </div>
                </div>
            </div>

            <div className='group'>
                <label
                    htmlFor='orgId'
                    className='mb-1 block text-sm font-medium text-gray-700'>
                    Organization ID
                </label>
                <input
                    type='text'
                    id='orgId'
                    className='w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm
                     transition-all duration-200 focus:border-transparent
                     focus:ring-2 focus:ring-newDesign-secondary'
                    placeholder='Enter your organization ID'
                    value={config.organizationId}
                    onChange={handleChange('organizationId')}
                />
            </div>

            <div className='group mt-4'>
                <label
                    htmlFor='apiToken'
                    className='mb-1 block text-sm font-medium text-gray-700'>
                    API Token
                </label>
                <input
                    type='password'
                    id='apiToken'
                    className='w-full rounded-lg border border-gray-300 px-4 py-2 shadow-sm
                     transition-all duration-200 focus:border-transparent
                     focus:ring-2 focus:ring-newDesign-secondary'
                    placeholder='Enter your API token'
                    value={config.apiToken}
                    onChange={handleChange('apiToken')}
                />
            </div>

            <Button
                onClick={() => onTestConnection(vendorKey)}
                disabled={!canTest}
                withIcon
                className='mt-4 w-full'>
                {config.isLoading ? (
                    <>
                        <Loader2 size={18} className='mr-2 animate-spin' />
                        <span>Testing Connection...</span>
                    </>
                ) : (
                    <>
                        <ShieldCheck size={18} className='mr-2' />
                        <span>Test Connection</span>
                    </>
                )}
            </Button>
        </div>
    );
}
