// VendorCard.tsx
import { Check, X } from 'lucide-react';
import React from 'react';

import { cn } from '@/lib/utils.ts';

import type { Vendor, VendorConfig } from '../types.ts';
import { MerakiLogo } from './CiscoMerakiLogo';
import { JuniperMistLogo } from './JuniperMistLogo';

interface VendorCardProps {
    vendorKey: Vendor;
    config: VendorConfig;
    selectedVendor: Vendor | null;
    onSelect: (vendor: Vendor) => void;
    onDisconnect: (vendor: Vendor) => void;
}

export function VendorCard({
    vendorKey,
    config,
    selectedVendor,
    onSelect,
    onDisconnect,
}: VendorCardProps) {
    const getVendorName = (vendor: Vendor): string => {
        switch (vendor) {
            case 'juniper/mist':
                return 'Juniper Mist';
            case 'cisco/meraki':
                return 'Cisco Meraki';
            default:
                return 'Unknown Vendor';
        }
    };

    const getVendorLogo = (vendor: Vendor) =>
        vendor === 'juniper/mist' ? (
            <JuniperMistLogo className='h-full w-full' />
        ) : (
            <MerakiLogo className='h-full w-full' />
        );

    const isActive = selectedVendor === vendorKey;

    return (
        <button
            className={cn(
                'relative cursor-pointer overflow-hidden rounded-xl border p-6 transition-all duration-200',
                'border-gray-200 hover:border-blue-200 hover:shadow-md',
                {
                    'border-newDesign-secondary bg-blue-50 shadow-md': isActive,
                    'bg-green-50': config.isConnected,
                },
            )}
            onClick={() => !config.isConnected && onSelect(vendorKey)}>
            <div className='absolute right-0 top-0 h-32 w-32 opacity-5'>
                {getVendorLogo(vendorKey)}
            </div>

            <div className='relative flex items-start gap-4'>
                <div className='h-12 w-12 flex-shrink-0'>
                    {getVendorLogo(vendorKey)}
                </div>

                <div className='flex-1'>
                    <h4 className='mb-1 text-lg font-medium'>
                        {getVendorName(vendorKey)}
                    </h4>

                    <p className='text-sm text-newDesign-text-secondary'>
                        {config.isConnected ? `Connected` : 'Not connected'}
                    </p>
                </div>
                {config.isConnected && (
                    <div className='flex items-center gap-2'>
                        <span className='flex items-center gap-1 text-sm text-newDesign-success'>
                            <Check size={16} />
                            Active
                        </span>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onDisconnect(vendorKey);
                            }}
                            className='rounded-full p-1 text-gray-400 transition-colors hover:bg-red-50 hover:text-newDesign-error'
                            title='Disconnect'>
                            <X size={16} />
                        </button>
                    </div>
                )}
            </div>
        </button>
    );
}
