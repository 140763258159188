import React from 'react';

interface MerakiLogoProps {
    className?: string;
}

export const MerakiLogo: React.FC<MerakiLogoProps> = ({ className = '' }) => (
    <svg
        className={className}
        viewBox='0 0 112 112'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect width='112' height='112' rx='56' fill='#5AA646' />
        <path
            d='M23.894 40.3202C23.894 36.1965 27.237 32.8535 31.3607 32.8535V32.8535C35.4844 32.8535 38.8274 36.1965 38.8274 40.3202V68.6935C38.8274 72.8172 35.4844 76.1602 31.3607 76.1602V76.1602C27.237 76.1602 23.894 72.8172 23.894 68.6935V40.3202Z'
            fill='white'
        />
        <path
            d='M40.9737 51.3886C39.2375 47.6482 40.8623 43.2085 44.6027 41.4724V41.4724C48.3432 39.7362 52.7828 41.361 54.519 45.1014L64.1602 65.8727C65.8963 69.6132 64.2715 74.0528 60.5311 75.789V75.789C56.7906 77.5251 52.351 75.9003 50.6148 72.1599L40.9737 51.3886Z'
            fill='white'
        />
        <path
            d='M61.8804 51.3886C60.1443 47.6482 61.769 43.2085 65.5095 41.4724V41.4724C69.2499 39.7362 73.6896 41.361 75.4257 45.1014L85.0669 65.8727C86.803 69.6132 85.1783 74.0528 81.4378 75.789V75.789C77.6974 77.5251 73.2577 75.9003 71.5216 72.1599L61.8804 51.3886Z'
            fill='white'
        />
    </svg>
);
