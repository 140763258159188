export type Vendor = 'juniper/mist' | 'cisco/meraki';

export interface VendorConfig {
    organizationId?: string;
    apiToken?: string;
    isConnected: boolean;
    isLoading: boolean;
    lastTested?: string;
}

export interface VendorConfigs {
    [key: string]: VendorConfig;
}

export const initialVendorConfigs: VendorConfigs = {
    'juniper/mist': {
        organizationId: '',
        apiToken: '',
        isConnected: false,
        isLoading: false,
    },
    'cisco/meraki': {
        organizationId: '',
        apiToken: '',
        isConnected: false,
        isLoading: false,
    },
    // 't1': {
    //     organizationId: '',
    //     apiToken: '',
    //     isConnected: false,
    //     isLoading: false,
    // },
    // 't2': {
    //     organizationId: '',
    //     apiToken: '',
    //     isConnected: false,
    //     isLoading: false,
    // },
    // 't3': {
    //     organizationId: '',
    //     apiToken: '',
    //     isConnected: false,
    //     isLoading: false,
    // },
};

// Simulate testing the connection
export async function testVendorConnection(
    vendor: Vendor,
    config: VendorConfig,
): Promise<VendorConfig> {
    try {
        await new Promise((resolve) => setTimeout(resolve, 1500));
        return {
            ...config,
            isConnected: true,
            isLoading: false,
            lastTested: new Date().toISOString(),
        };
    } catch {
        return {
            ...config,
            isConnected: false,
            isLoading: false,
            lastTested: new Date().toISOString(),
        };
    }
}

export function disconnectVendorConfig(): VendorConfig {
    return {
        organizationId: '',
        apiToken: '',
        isConnected: false,
        isLoading: false,
    };
}
