import React, { useState } from 'react';
import { HiOutlineLockClosed } from 'react-icons/hi';
import { PiEyeClosed, PiEye } from 'react-icons/pi';
import { PiGearLight } from 'react-icons/pi';

import PasswordForm from './components/PasswordForm.tsx';
import UserSettingsForm from './components/UserSettingsForm.tsx';

const UserSettings = () => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <section className='flex flex-col gap-4 rounded-lg border border-newDesign-divider p-6 pb-4'>
            <div className='flex items-center'>
                <PiGearLight className='mr-2 size-6 stroke-[5] text-newDesign-primary' />
                <h2 className='text-lg font-semibold'>User Settings</h2>
            </div>

            <UserSettingsForm />

            <div className='flex items-center'>
                <HiOutlineLockClosed className='mr-2 size-6 stroke-[1.5] text-newDesign-primary' />
                <h2 className='text-lg font-semibold'>Password</h2>
                <div className='flex flex-1 items-center justify-end'>
                    <button
                        onClick={() => setShowPassword(!showPassword)}
                        className='flex gap-2 text-newDesign-primary'>
                        {showPassword ? (
                            <>
                                <p className='text-xs'>Hide password</p>{' '}
                                <PiEyeClosed className='size-4' />
                            </>
                        ) : (
                            <>
                                <p className='text-xs'>Show password</p>
                                <PiEye className='size-4' />
                            </>
                        )}
                    </button>
                </div>
            </div>
            <PasswordForm showPassword={showPassword} />
        </section>
    );
};

export default UserSettings;
